<script setup lang="ts">
const manageTypes = { change_flight: 'CHANGE_FLIGHT', add_baggage: 'BAGGAGE', refund: 'CANCELLATION' };

const auth = useAuth();

const { navigationPath } = useNavigation();
</script>

<template>
  <main class="flex flex-col items-center bg-white relative">
    <Transition
      appear
      enter-from-class="-translate-y-[200px] opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      enter-active-class="transform transition-all duration-200"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="-translate-y-[200px] opacity-0"
      leave-active-class="transform transition-all duration-200"
    >
      <div class="sticky container top-[70px] overflow-show z-[40]">
        <SharedHomeLoginBanner class="absolute top-0 right-10 h-auto min-h-[170px] shadow-md" />
      </div>
    </Transition>

    <SharedHomeBanner
      :title="$t('global.homebannertitle')"
      :subtitle="$t('global.homebannerdesc')"
    />

    <section class="container mb-40 mt-[105px]">
      <SharedPromoCarousel />
    </section>

    <section class="container mb-40">
      <h2 class="font-bold text-gray-darkest text-extra mb-20">{{ $t('after_sales_general.manageyourbooking') }}</h2>

      <div class="flex gap-10">
        <SharedManageOrderBox
          v-for="order in ['change_flight', 'add_baggage', 'refund']"
          :key="order"
          :code="order"
          :path="navigationPath({ name: auth.loggedIn ? 'account-order' : 'order', query: { manage: manageTypes[order as keyof typeof manageTypes] } })"
          :data-testid="'manage-' + order + '-container'"
          class="flex-grow"
        />
      </div>
    </section>

    <section class="container mb-40">
      <FlightRecommendation />
    </section>

    <section class="container mb-40">
      <HotelRecommendation />
    </section>

    <section class="container mb-40">
      <HotelSmartSaving />
    </section>

    <section class="container mb-40">
      <HotelPopularDestination />
    </section>

    <section class="container mb-40">
      <HotelNearestCountries />
    </section>

    <section class="mb-40 w-full bg-whiter py-40">
      <div
        class="container w-full"
        data-testid="whyAirpaz-information-container"
      >
        <SharedAirpazFeatures :type="['flight', 'hotel']" />
      </div>
    </section>

    <section class="container py-40 flex justify-center mb-[200px]">
      <AirlinePartners
        :limit="18"
        class="max-w-[805px]"
      />
    </section>

    <section class="w-full">
      <SharedAppBanner />
    </section>

    <section class="pt-[80px] pb-40">
      <FeedbackForm />
    </section>

    <section class="container py-40">
      <SharedSeoFooter />
    </section>

    <SharedPromotionBanner />
  </main>
</template>
